import * as React from "react"
import Layout from '../components/layout'
import FeatureCard from "../components/featureCard"
import RoomCardList from "../components/roomCardList"
import { Link } from 'gatsby'
import CovidModal from "../components/covidModal"

const IndexPage = () => {
  return (
    <Layout showHomePageNav="showHomePageNav" pageTitle="HomePage">
      <div className="banner-area">
				<div className="container">
					<div className="row justify-content-center height align-items-center">
            <div className="d-none d-xl-block col-xl-2 logo-vertical">
                <h2 className="d-block">P</h2>
                <h2 className="d-block">A</h2>
                <h2 className="d-block">N</h2>
                <h2 className="d-block">D</h2>
                <h2 className="d-block"><span>99</span></h2>
            </div>
						<div className="col-lg-8 col-xl-10">
							<div className="banner-content text-center">
								<span className="text-white top text-uppercase">A place to</span>
								<h1 className="text-white text-uppercase">Work, Learn and Meet</h1>
                  <Link className="primary-btn d-inline-flex align-items-center" to="/overview"><span className="mr-10">Bekijk onze ruimtes</span><span className="lnr lnr-arrow-right"></span></Link>
							</div>
						</div>
					</div>
				</div>
			</div>

      <CovidModal/> 

      <section className="featured-area">
				<div className="container">

					<div className="row">
            <FeatureCard featureTitle="Pand 99" featureIcon="apartment">
              <p>Pand 99 biedt u een praktische en professionele oplossing bij je zoektocht naar een vergaderruimte, opleidingsruimte, naar een plaats voor een kort gesprek, workshop of gewoon een plaats om flexibel te werken.</p>
            </FeatureCard>

            <FeatureCard featureTitle="Moderne ruimtes" featureIcon="magic-wand">
              <p>Ontvang uw zakenpartner, klanten of medewerkers in één van onze comfortabele, modern ingerichte vergaderruimtes of werkplekken.</p>
            </FeatureCard>

            <FeatureCard featureTitle="Bereikbaarheid" featureIcon="map-marker">
              <p>Pand 99 is gelegen in het centrum van Sint-Gillis Dendermonde en is vlot bereikbaar met het openbaar vervoer en vanuit het industrieterrein Hoogveld.</p>
            </FeatureCard>

            <FeatureCard featureTitle="Het Huis Van Cleophas" featureIcon="dinner">
              <p>Wenst u een meeting in Pand99 te combineren met een lunch of diner? Dan bent u bij onze partner Het Huis Van Cleophas aan het juiste adres!</p>
							<Link to="/cleophas">Meer weten</Link>
            </FeatureCard>

            <FeatureCard featureTitle="Hint Labyrinth" featureIcon="dice">
              <p>Combineer uw bedrijfsevent met een escaperoom bij <a href="https://hintlabyrinth.be">Hintlabyrinth</a></p>
            </FeatureCard>

						<FeatureCard featureTitle="Online reservatie" featureIcon="rocket">
              <p>Makkelijk en snel online reserveren en onmiddellijk de beschikbaarheid van de ruimtes nagaan.</p>
            </FeatureCard>

            <FeatureCard featureTitle="Standaarduitrusting" featureIcon="cog">
              <p>Klanten van Pand 99 kunnen gebruik maken van onder meer: 55 inch presentatiescherm, printer, koelkast en microgolfoven, een snelle wifiverbinding, whiteboard, flipover</p>
            </FeatureCard>
					</div>
				</div>
			</section>

      <section className="service-area">
			  <div className="container">
					<div className="row justify-content-center">
						<div className="col-lg-8">
							<div className="section-title text-center">
								<h3 className="text-white">Onze Ruimtes</h3>
								<span className="text-white text-uppercase">Uw inspiratiebron</span>
							</div>
						</div>
					</div>
            <RoomCardList textColorClass="text-white"></RoomCardList>
        </div>
			</section>
    </Layout>
  )
}

export default IndexPage
