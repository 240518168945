import * as React from 'react'

const FeatureCard = ({ featureTitle, featureIcon, children }) => {
 return (
    <div className="col-md-4">
        <div className="single-feature d-flex flex-wrap justify-content-between">
            <div className="icon d-flex align-items-center justify-content-center">
                <span className={"lnr lnr-" + featureIcon}></span>
            </div>	
            <div className="desc">
                <h6 className="title text-uppercase">{featureTitle}</h6>
                {children}
            </div>
        </div>
    </div>
 )
}

export default FeatureCard