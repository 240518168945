import * as React from 'react'
import RoomCard from './roomCard';
import roomData from "/static/rooms.json"

const RoomCardList = ({textColorClass, children }) => {
    return (
        <div className="row">
            {roomData.map((room, index) => (
                <RoomCard roomData={room} textColorClass={textColorClass} key={index}></RoomCard>
            ))}
        </div>
    );
   }
   
export default RoomCardList