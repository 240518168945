import * as React from 'react'
import { Link } from 'gatsby'

const CovidModal = ({ children }) => {
 return (
    <div className="modal" id="covid-modal" tabIndex="-1" role="dialog">
        <div className="modal-dialog" role="document">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">Update</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <p>
                        <strong>Beste klant,</strong><br/>
                        <br />
                        <br />
                        Omwille van pensionering zullen de activiteiten van Pand99 eindigen vanaf september 2024. 
                        <br />
                        <br />
                        Graag bedanken wij al onze klanten voor het vertrouwen en fijne samenwerking.
                        <br />
                        <br />
                        Met vriendelijke groeten,
                        <br />
                        PAND99</p>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Sluiten</button>
                </div>
            </div>
        </div>
    </div>
 )
}

export default CovidModal